import React from "react";
// import LatticeGrid from "lattice/lib/components/LatticeGrid";
import SEO from "../components/functional/seo";
import DesignStory from "../components/molecules/design-story";
import DesignProps from "../components/molecules/design-props";
import Input from "../components/atoms/input";
import AlarmOnIcon from "../components/atoms/icons/alarm-on";

function DesignInput() {
  return (
    <div className="design__content">
      <SEO title="Design System - Input" />
      <h2>Input</h2>

      <div className="design__stories">
        <div className="design__story-section">
          {/* <h5>Input</h5> */}
          <DesignStory
            title="Text Input"
            type="component"
            component={Input}
            compact
            props={{
              placeholder: "Enter First Name",
              title: "First Name",
              type: "text",
              name: "firstName",
              id: "designFirstName",
              color: "green",
            }}
          />
          <DesignStory
            title="Email Input"
            type="component"
            component={Input}
            compact
            props={{
              placeholder: "Enter Email",
              title: "Email",
              type: "email",
              name: "email",
              id: "designEmail",
              color: "blue",
            }}
          />
          <DesignStory
            title="Password"
            type="component"
            component={Input}
            compact
            props={{
              placeholder: "Enter Password",
              title: "Password",
              type: "password",
              name: "password",
              id: "designPassword",
              color: "purple",
            }}
          />
          <DesignStory
            title="Input with Icon"
            type="component"
            component={Input}
            compact
            props={{
              placeholder: "Enter Text",
              title: "Icon Text",
              type: "text",
              name: "textIcon",
              id: "designTextIcon",
              color: "green",
              icon: AlarmOnIcon,
            }}
          />
          <DesignStory
            title="Max Length"
            type="component"
            component={Input}
            compact
            props={{
              placeholder: "Enter Text",
              title: "Text Input",
              type: "text",
              name: "textMaxInput",
              id: "designTextMaxInput",
              color: "green",
              maxLength: 25,
            }}
          />
        </div>

        <div className="design__story-section">
          <DesignProps component={Input} title="Input Properties" />
        </div>
      </div>
    </div>
  );
}

export default DesignInput;
